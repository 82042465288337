
    import { Component, Vue, Watch } from 'vue-property-decorator';
    import ModalTemplate from '@/components/layouts/ModalTemplate.vue';

    import LandPlotModalTop from './LandPlotModalTop.vue';
    import LandPlotModalBottom from './LandPlotModalBottom.vue';
    import LandPlotGalleryFullScreen from '@/components/modals/LandPlot/LandPlotGalleryFullScreen.vue';

    import { landPlotStore } from '@/store/modules/LandPLot';

    @Component({
        components: {
            ModalTemplate,
            LandPlotModalTop,
            LandPlotModalBottom,
            LandPlotGalleryFullScreen,
        },
    })
    export default class LandPlotModal extends Vue {
        indexActiveSlider = 0;

        get landPlot() {
            return landPlotStore.LAND_PLOT_CARD_IN_MODAL;
        }

        isActivePlotModalGalleryFullScreen = false;
        isActiveHouseModalGalleryFullScreen = false;

        openInFullScreenPlotModalGallery(data: number) {
            this.indexActiveSlider = data;
            this.isActivePlotModalGalleryFullScreen = true;
        }

        openInFullScreenHouseModalGallery(data: number) {
            this.indexActiveSlider = data;
            this.isActiveHouseModalGalleryFullScreen = true;
        }

        @Watch('isActivePlotModalGalleryFullScreen')
        onScroll() {
            if (
                this.isActivePlotModalGalleryFullScreen ||
                this.isActiveHouseModalGalleryFullScreen
            )
                document.body.style.overflow = 'hidden';
            else document.body.style.overflow = 'auto';
        }
    }
