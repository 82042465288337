
    import { Component, Vue } from 'vue-property-decorator';
    import MainView from '@/views/MainView.vue';
    // @ts-ignore
    import styleAsString from 'raw-loader!./assets/styles/utils/fonts.txt';
    import { landPlotStore } from './store/modules/LandPLot';
    import { cardSettingsStore } from './store/modules/CardSettings';

    @Component({
        components: { MainView },
    })
    export default class App extends Vue {
        // font imports

        beforeCreate() {
            const styleElement = document.createElement('style');
            styleElement.innerHTML = styleAsString
                .split('%URL%')
                .join(
                    `http${
                        process.env.VUE_APP_COMPONENT_SECURE === 'true'
                            ? 's'
                            : ''
                    }://${process.env.VUE_APP_COMPONENT_DOMAIN}`,
                );
            document.head.appendChild(styleElement);
        }

        mounted() {
            window.addEventListener('resize', () => {
                let vh = window.innerHeight * 0.01;
                document.documentElement.style.setProperty('--vh', `${vh}px`);
            });
            cardSettingsStore.SET_MAP_DOM_RECT(
                this.$el.getBoundingClientRect(),
            );
            window.addEventListener('resize', () =>
                cardSettingsStore.SET_MAP_DOM_RECT(
                    this.$el.getBoundingClientRect(),
                ),
            );
            window.addEventListener('scroll', () =>
                cardSettingsStore.SET_MAP_DOM_RECT(
                    this.$el.getBoundingClientRect(),
                ),
            );
            window.addEventListener('clickFromTilda', () => {
                landPlotStore.SET_CURRENT_SECTION_ZONE('southwest');
            });
        }
        beforeDestroy() {
            window.removeEventListener('resize', () => {
                let vh = window.innerHeight * 0.01;
                document.documentElement.style.setProperty('--vh', `${vh}px`);
            });
            window.removeEventListener('resize', () =>
                cardSettingsStore.SET_MAP_DOM_RECT(
                    this.$el.getBoundingClientRect(),
                ),
            );
            window.removeEventListener('scroll', () =>
                cardSettingsStore.SET_MAP_DOM_RECT(
                    this.$el.getBoundingClientRect(),
                ),
            );
            window.removeEventListener('clickFromTilda', () => {
                landPlotStore.SET_CURRENT_SECTION_ZONE('southwest');
            });
        }
    }
