
    import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
    @Component({
        components: {},
    })
    export default class CustomCheckbox extends Vue {
        @Prop({ default: 'md' }) size!: string;
        @Prop({ default: false }) value!: boolean;
        @Prop({ default: 'id' }) inputId!: string;
        @Prop({ default: 'inp-name' }) inputName!: string;

        val: boolean = false;

        @Watch('val')
        on() {
            if (this.value === this.val) return;
            this.$emit('input', this.val);
            this.$emit('change', this.val);
        }

        @Watch('value')
        on2() {
            if (this.value === this.val) return;
            this.val = this.value;
        }

        created() {
            if (this.value === this.val) return;
            this.val = this.value;
        }
    }
