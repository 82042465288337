
    import { Component, Vue, Prop } from 'vue-property-decorator';
    import ModalTemplate from '@/components/layouts/ModalTemplate.vue';
    import CustomButton from '@/components/commons/CustomControls/CustomButton.vue';
    import { landPlotStore } from '@/store/modules/LandPLot';
    import { cashSpacer } from '@/helpers/cashHelpers';
    import { cardSettingsStore } from '@/store/modules/CardSettings';
    import { ILandPlot } from '@/types/LandPLot';

    @Component({
        components: {
            ModalTemplate,
            CustomButton,
        },
    })
    export default class ListMobWindow extends Vue {
        get landPlotList() {
            return landPlotStore.CURRENT_FILTERED_LAND_PLOTS;
        }

        oldPrice(index: number) {
            return this.landPlotList[index].plot.discount.showFullPrice
                ? this.landPlotList[index].plot.cost
                : this.landPlotList[index].plot.costOneHundred;
        }

        currentPrice(index: number) {
            if (this.landPlotList[index].plot.discount.showFullPrice) {
                return (
                    this.landPlotList[index].plot.newCost ||
                    this.landPlotList[index].plot.cost
                );
            } else {
                return (
                    this.landPlotList[index].plot.newCostOneHundred ||
                    this.landPlotList[index].plot.cost
                );
            }
        }

        infoPriceTitle(item: ILandPlot) {
            return item.plot.discount.showFullPrice ? 'Цена' : 'Цена за сотку';
        }

        price(data: number) {
            return cashSpacer(data, ' ');
        }

        async openInDetailCardWindow(data: number | string) {
            this.$emit('close');
            await landPlotStore.CREATE_LAND_PLOT_CARD_IN_MODAL(data);
            cardSettingsStore.LAND_PLOT_CARD_IN_MODAL_SET_SHOW_STATUS(true);
        }
    }
