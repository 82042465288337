import { render, staticRenderFns } from "./SoutheastDetail.vue?vue&type=template&id=1746f058"
import script from "./SoutheastDetail.vue?vue&type=script&lang=ts"
export * from "./SoutheastDetail.vue?vue&type=script&lang=ts"
function injectStyles (context) {
  
  
}

/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  ,true
)

export default component.exports