
    import { Component, Vue, Prop } from 'vue-property-decorator';
    import { landPlotStore } from '@/store/modules/LandPLot';
    @Component({
        components: {},
    })
    export default class CustomFilterButton extends Vue {
        @Prop({ default: false }) filterIsOpen!: boolean;

        get filterStatus() {
            return landPlotStore.FILTER_STATUS;
        }
    }
