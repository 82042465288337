var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('ModalTemplate',{attrs:{"filterBtn":true,"mapInset":true,"closeBtn":true,"scrollBodyLock":true},on:{"openList":function($event){return _vm.$emit('openList')},"close":function($event){return _vm.$emit('close')}}},[_c('div',{staticClass:"list-mob-window"},[(_vm.landPlotList && _vm.landPlotList.length)?_c('div',{staticClass:"list-mob-window__wrapper"},_vm._l((_vm.landPlotList),function(item,i){return _c('div',{key:`list-mob-window-item-${item.id}`,staticClass:"list-mob-window__item",on:{"click":function($event){return _vm.openInDetailCardWindow(item.plot.number)}}},[_c('div',{staticClass:"list-mob-window__item-header"},[_c('h3',{staticClass:"list-mob-window__item-header-title text-md ff-montserrat"},[_vm._v(" "+_vm._s(item.house ? item.plot.name : 'Участок')+" ")]),_c('p',{staticClass:"list-mob-window__item-header-status ff-raleway text-xsm",class:{
                            'text-success-dark':
                                item.plot.status === 'free',
                            'text-warning': item.plot.status === 'booked',
                            'text-danger': item.plot.status === 'occupied',
                        }},[(item.plot.status === 'free')?_c('span',[_vm._v(" Свободен ")]):(item.plot.status === 'booked')?_c('span',[_vm._v(" Забронирован ")]):(item.plot.status === 'occupied')?_c('span',[_vm._v(" Занят ")]):_vm._e()])]),_c('div',{staticClass:"list-mob-window__item-body custom-list-mob ff-raleway text-xsm"},[_c('div',{staticClass:"custom-list-mob__item"},[_c('p',{staticClass:"custom-list-mob__item-name"},[_vm._v("Адрес")]),_c('p',{staticClass:"custom-list-mob__item-value"},[_vm._v(" "+_vm._s(item.plot.street.name)+", "+_vm._s(item.plot.houseNumber)+" ")])]),(item.house)?_c('div',{staticClass:"custom-list-mob__item"},[_c('p',{staticClass:"custom-list-mob__item-name text-secondary-dark"},[_vm._v(" Площадь дома, м2 ")]),_c('p',{staticClass:"custom-list-mob__item-value"},[_vm._v(" "+_vm._s(item.house.area)+" ")])]):_vm._e(),_c('div',{staticClass:"custom-list-mob__item"},[_c('p',{staticClass:"custom-list-mob__item-name"},[_vm._v(" Площадь участка, соток ")]),_c('p',{staticClass:"custom-list-mob__item-value"},[_vm._v(" "+_vm._s(item.plot.area)+" ")])]),(item.house && item.plot.parameters.length)?_c('div',{staticClass:"custom-list-mob__item"},[_c('p',{staticClass:"custom-list-mob__item-name text-secondary-dark"},[_vm._v(" Сроки сдачи ")]),_c('p',{staticClass:"custom-list-mob__item-value"},[_vm._v(" "+_vm._s(item.plot.parameters[0].value)+" ")])]):_vm._e()]),_c('div',{staticClass:"list-mob-window__item-price"},[_c('p',{staticClass:"list-mob-window__item-price-text text-xsm ff-raleway"},[_vm._v(" "+_vm._s(_vm.infoPriceTitle(item))+" ")]),_c('p',{staticClass:"list-mob-window__item-price-value text-danger text-nr ff-raleway"},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(
                                item.plot.newCostOneHundred ||
                                item.plot.newCost
                            ),expression:"\n                                item.plot.newCostOneHundred ||\n                                item.plot.newCost\n                            "}],staticClass:"text-xsm text-secondary list-mob-window__old-price"},[_vm._v(" "+_vm._s(_vm.price(_vm.oldPrice(i)))+" ₽ ")]),_c('span',[_vm._v(" "+_vm._s(_vm.price(_vm.currentPrice(i)))+" ₽ ")])])])])}),0):_c('div',{staticClass:"list-mob-window__void"},[_c('p',{staticClass:"list-mob-window__void-text text-warning text-lg"},[_vm._v(" По выбранным фильтрам не найдено участков ")])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }