
    import { Component, Vue } from 'vue-property-decorator';
    import ContainerCustom from '@/components/layouts/ContainerCustom.vue';
    import MapHeader from '@/components/map/MapHeader.vue';
    import MapFooter from '@/components/map/MapFooter.vue';
    import FilterWindow from '@/components/map/FilterWindow.vue';
    import ListMobWindow from '@/components/map/ListMobWindow.vue';
    import MapFigure from '@/components/map/MapFigure.vue';
    import MapYandex from '@/components/map/MapYandex.vue';

    import CardHouse from '@/components/house/CardHouse.vue';
    import CardPlotZone from '@/components/map/map-sections/CardPlot.vue';
    import CardRelaxationZone from '@/components/map/map-sections/CardRelaxation.vue';
    import LandPLotModal from '@/components/modals/LandPlot/index.vue';
    import { landPlotStore } from '@/store/modules/LandPLot';
    import { cardSettingsStore } from '@/store/modules/CardSettings';

    @Component({
        components: {
            ContainerCustom,
            MapHeader,
            MapFooter,
            FilterWindow,
            ListMobWindow,
            CardHouse,
            CardPlotZone,
            CardRelaxationZone,
            MapFigure,
            MapYandex,
            LandPLotModal,
        },
    })
    export default class Map extends Vue {
        isPopupVisible = true;
        isFilterWindowVisible = false;
        isListMobWindowVisible = false;
        isFilterActive = false;
        isMasterPlan = true;

        get isWindowHouseWindowVisible() {
            return cardSettingsStore.IS_SHOW_LAND_PLOT_CARD_IN_MODAL;
        }

        changeTab(data: string) {
            this.isMasterPlan = data === 'map-plan' ? false : true;
        }

        closeFilterWindow(data: any) {
            if (data && data.status) this.isFilterActive = true;
            else this.isFilterActive = false;
            this.isFilterWindowVisible = false;
        }

        onCloseHouseWindow() {
            cardSettingsStore.LAND_PLOT_CARD_IN_MODAL_SET_SHOW_STATUS(false);
        }
    }
