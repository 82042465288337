
    import { Component, Vue } from 'vue-property-decorator';
    import Map from '@/components/map/Map.vue';

    import { landPlotStore } from '@/store/modules/LandPLot';
    import { facilityStore } from '@/store/modules/Facility';

    @Component({
        components: { Map },
    })
    export default class MainView extends Vue {
        async created() {
            await Promise.all([landPlotStore.LOAD_LAND_PLOTS()]);
        }
    }
