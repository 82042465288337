// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@/assets/img/svg/check.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".custom-checkbox__input{display:none}.custom-checkbox__input:checked+.custom-checkbox__label:before{background-color:#db6033;border-color:#db6033}.custom-checkbox__input:checked+.custom-checkbox__label:after{opacity:1}.custom-checkbox__label{cursor:pointer;transition:all .3s ease;display:inline-flex;align-items:center;padding-left:36px;position:relative;-webkit-user-select:none;-moz-user-select:none;user-select:none;min-height:24px}.custom-checkbox__label:before{background-color:#fff;border-radius:6px;border:1.5px solid #dcdcdc}.custom-checkbox__label:after,.custom-checkbox__label:before{content:\"\";width:24px;height:24px;position:absolute;left:0;top:50%;transform:translateY(-50%);transition:all .3s ease}.custom-checkbox__label:after{background-position:50%;background-size:11px;background-repeat:no-repeat;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");opacity:0}", ""]);
// Exports
module.exports = exports;
