var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('button',{staticClass:"btn text-nr ff-raleway",class:{
        'btn-md': _vm.size === 'md',
        'btn-lg': _vm.size === 'lg',
        'btn-sm': _vm.size === 'sm',
        'btn-danger': _vm.theme === 'danger',
        'btn-danger-outher': _vm.theme === 'danger-outher',
    },on:{"click":function($event){return _vm.$emit('click')}}},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }