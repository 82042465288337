
    import { landPlotStore } from '@/store/modules/LandPLot';
    import { Component, Vue } from 'vue-property-decorator';
    import { cashSpacer } from '@/helpers/cashHelpers';
    import CardTemplate from '@/components/layouts/CardTempate.vue';
    import { cardSettingsStore } from '@/store/modules/CardSettings';
    @Component({
        components: { CardTemplate },
    })
    export default class CardHouse extends Vue {
        get isDiscount() {
            return this.card &&
                Object.keys(this.card).length &&
                this.card.discount.value
                ? true
                : false;
        }
        get oldPrice() {
            if (!this.card) return 0;
            return this.card.discount.showFullPrice
                ? this.card.price
                : this.card.costOneHundred;
        }

        get currentPrice() {
            if (!this.card) return 0;
            if (this.card.discount.showFullPrice) {
                return this.card.newPrice || this.card.price;
            } else {
                return this.card.newCostOneHundred || this.card.price;
            }
        }
        get infoPriceTitle() {
            return this.card && this.card.discount.showFullPrice
                ? 'Цена'
                : 'Цена за сотку';
        }
        get statusCardHouseShow() {
            return cardSettingsStore.STATUS_CARD_HOUSE_SHOW;
        }

        get objectStyle() {
            const mapWidth = cardSettingsStore.MAP_DOM_RECT.width;
            const mapHeight = cardSettingsStore.MAP_DOM_RECT.height;
            const x = this.position.x;
            const y = this.position.y;
            const widthPath = this.position.width;
            const heightPath = this.position.height;
            let widthCard = 0;
            let heightCard = 0;
            let top = 0;
            let left = x + widthPath / 2;

            if (
                this.$refs.houseCard &&
                this.$refs.houseCard instanceof Element
            ) {
                widthCard = this.$refs.houseCard.clientWidth;
                heightCard = this.$refs.houseCard.clientHeight;
            }

            if (x + widthPath / 2 < widthCard / 2) left = x + widthPath + 10;
            else if (x + widthPath / 2 + widthCard / 2 > mapWidth)
                left = mapWidth - widthCard / 2;

            if (heightCard > y && y + heightCard + heightPath <= mapHeight) {
                top = y + heightCard + heightPath;
            } else if (
                y + heightCard + heightPath > mapHeight &&
                heightCard > y
            ) {
                top = mapHeight;
                if (
                    x + widthCard / 2 < mapWidth &&
                    x - widthCard / 2 > widthCard / 2
                )
                    left = x - widthCard / 2;
                else if (x - widthCard / 2 < widthCard / 2)
                    left = x + widthCard;
            } else top = y;
            return {
                left: `${left}px`,
                top: `${top}px`,
                opacity: this.statusCardHouseShow ? '1' : '0',
                pointerEvents: this.statusCardHouseShow ? 'unset' : 'none',
            };
        }

        get card() {
            return landPlotStore.LAND_PLOT_CARD_MINI;
        }

        get position() {
            return cardSettingsStore.POSITION_CARD_HOUSE;
        }

        formatedCost(price: number) {
            return cashSpacer(price, ' ');
        }

        get formatedValues() {
            return this.card && Object.keys(this.card).length
                ? cashSpacer(this.card.price, ' ')
                : null;
        }
    }
