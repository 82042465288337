
    import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
    import Swiper, { Navigation } from 'swiper';
    import CloseIcon from '@/components/commons/icons/CloseIcon.vue';
    Swiper.use([Navigation]);
    @Component({
        components: { CloseIcon },
    })
    export default class LandPlotGalleryFullScreen extends Vue {
        @Prop({ default: () => [] }) landPlotImages!: string[];
        @Prop({ default: false }) imageFitContaine!: boolean;
        @Prop({ default: 0 }) indexActiveSlider!: number;

        sw: Swiper | null = null;
        initSwiper() {
            //@ts-ignore
            this.sw = new Swiper(this.$refs.swiperFullScreen, {
                spaceBetween: 20,
                watchSlidesVisibility: true,
                watchSlidesProgress: true,
                allowTouchMove: true,
                cssMode: true,
                slidesPerView: 1,
            });
        }

        @Watch('landPlotImages')
        on() {
            setTimeout(() => {
                if (this.sw) this.sw.destroy();
                this.initSwiper();
                window.dispatchEvent(new Event('resize'));
                setTimeout(() => {
                    if (this.sw) this.sw.update();
                });
            });
        }

        mounted() {
            this.initSwiper();
            if (this.sw && !this.sw.destroyed)
                this.sw.slideTo(this.indexActiveSlider);
        }

        beforeDestroy() {
            if (this.sw && !this.sw.destroyed) this.sw.destroy();
        }
    }
