
    import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
    import ModalTemplate from '@/components/layouts/ModalTemplate.vue';
    import CustomButton from '@/components/commons/CustomControls/CustomButton.vue';
    import CustomCheckbox from '@/components/commons/CustomInputs/CustomCheckbox.vue';
    import CustomRangeSelect from '@/components/commons/CustomInputs/CustomRangeSelect.vue';

    import { landPlotStore } from '@/store/modules/LandPLot';
    import { ILandPlotFilter, PlotStatus } from '@/types/LandPLot';
    import { IFacilityWithStatus } from '@/types/Facility';

    @Component({
        components: {
            ModalTemplate,
            CustomButton,
            CustomCheckbox,
            CustomRangeSelect,
        },
    })
    export default class FilterWindow extends Vue {
        plotArea = {
            min: 0,
            max: 15,
        };
        pricePlot = {
            min: 0,
            max: 10000000,
        };
        houseArea = {
            min: 0,
            max: 140,
        };

        floorsStatus: Array<IFacilityWithStatus> = [];
        facilitiesStatus: Array<IFacilityWithStatus> = [];

        get floors() {
            return landPlotStore.FLOORS;
        }

        get facilities() {
            return landPlotStore.FACALITIES;
        }

        get landPlotFilters() {
            return landPlotStore.ZONE_LAND_PLOT_FILTERS;
        }

        get hiddenHouseArea() {
            return !landPlotStore.HIDDEN_FILTERS.houseAreaHidden;
        }

        get hiddenLandPlotArea() {
            return !landPlotStore.HIDDEN_FILTERS.landPLotAreaHidden;
        }

        // максимумы и минимумы
        get minPlotArea() {
            return landPlotStore.PLOT_AREA_GAP.min;
        }
        get maxPlotArea() {
            return landPlotStore.PLOT_AREA_GAP.max;
        }

        get minPricePlot() {
            return landPlotStore.PRICE_GAP.min;
        }
        get maxPricePlot() {
            return landPlotStore.PRICE_GAP.max;
        }

        get minHouseArea() {
            return landPlotStore.HOUSE_AREA_GAP.min;
        }
        get maxHouseArea() {
            return landPlotStore.HOUSE_AREA_GAP.max;
        }

        get priceLandPlotGap() {
            return {
                min: landPlotStore.ZONE_LAND_PLOT_FILTERS.landPLotPriceMin,
                max: landPlotStore.ZONE_LAND_PLOT_FILTERS.landPLotPriceMax,
            };
        }
        set priceLandPlotGap(data: { min: number; max: number }) {
            landPlotStore.SET_ZONE_LAND_PLOT_FILTERS({
                landPLotPriceMin: data.min,
                landPLotPriceMax: data.max,
            });
        }
        //
        get areaLandPlotGap() {
            return {
                min: landPlotStore.ZONE_LAND_PLOT_FILTERS.landPLotAreaMin,
                max: landPlotStore.ZONE_LAND_PLOT_FILTERS.landPLotAreaMax,
            };
        }
        set areaLandPlotGap(data: { min: number; max: number }) {
            landPlotStore.SET_ZONE_LAND_PLOT_FILTERS({
                landPLotAreaMax: data.max,
                landPLotAreaMin: data.min,
            });
        }
        //
        get houseAreaGap() {
            return {
                min: landPlotStore.ZONE_LAND_PLOT_FILTERS.houseAreaMin,
                max: landPlotStore.ZONE_LAND_PLOT_FILTERS.houseAreaMax,
            };
        }
        set houseAreaGap(data: { min: number; max: number }) {
            landPlotStore.SET_ZONE_LAND_PLOT_FILTERS({
                houseAreaMin: data.min,
                houseAreaMax: data.max,
            });
        }

        // c домом
        get withHouse() {
            return landPlotStore.ZONE_LAND_PLOT_FILTERS.withHouse;
        }
        set withHouse(data: boolean) {
            landPlotStore.SET_ZONE_LAND_PLOT_FILTERS({ withHouse: data });
        }
        // без дома
        get withoutHouse() {
            return landPlotStore.ZONE_LAND_PLOT_FILTERS.withoutHouse;
        }
        set withoutHouse(data: boolean) {
            landPlotStore.SET_ZONE_LAND_PLOT_FILTERS({ withoutHouse: data });
        }
        // свободные
        get free() {
            return !!(
                landPlotStore.ZONE_LAND_PLOT_FILTERS.status.indexOf(
                    PlotStatus.free,
                ) + 1
            );
        }
        set free(data: boolean) {
            const result: PlotStatus[] = this.free
                ? landPlotStore.ZONE_LAND_PLOT_FILTERS.status.filter(
                      elem => elem != PlotStatus.free,
                  )
                : [
                      ...landPlotStore.ZONE_LAND_PLOT_FILTERS.status,
                      PlotStatus.free,
                  ];

            landPlotStore.SET_ZONE_LAND_PLOT_FILTERS({ status: result });
        }
        // Забронированные
        get booked() {
            return !!(
                landPlotStore.ZONE_LAND_PLOT_FILTERS.status.indexOf(
                    PlotStatus.booked,
                ) + 1
            );
        }
        set booked(data: boolean) {
            const result: PlotStatus[] = this.booked
                ? landPlotStore.ZONE_LAND_PLOT_FILTERS.status.filter(
                      elem => elem != PlotStatus.booked,
                  )
                : [
                      ...landPlotStore.ZONE_LAND_PLOT_FILTERS.status,
                      PlotStatus.booked,
                  ];

            landPlotStore.SET_ZONE_LAND_PLOT_FILTERS({ status: result });
        }

        get allowedFloors() {
            return landPlotStore.ZONE_LAND_PLOT_FILTERS_FLOORS;
        }

        get zone() {
            return landPlotStore.CURRENT_SECTION_ZONE;
        }

        async applyFilter() {
            await landPlotStore.APPLY_ZONE_LAND_PLOT_FILTERS();
            landPlotStore.SET_FILTER_STATUS(true);
            this.$emit('close');
        }

        async applyFilterAndShowList() {
            await landPlotStore.APPLY_ZONE_LAND_PLOT_FILTERS();
            landPlotStore.SET_FILTER_STATUS(true);

            this.$emit('close');
            this.$emit('openFilter');
        }

        resetRangeData() {
            this.plotArea = {
                min: 0,
                max: 15,
            };
            this.pricePlot = {
                min: 0,
                max: 10000000,
            };
            this.houseArea = {
                min: 0,
                max: 140,
            };
        }

        resetFilter() {
            this.resetFacilitiesList();
            this.resetFloorsList();
            landPlotStore.ZONE_RESET_LAND_PLOT_FILTERS();
            this.$emit('close');
            landPlotStore.SET_FILTER_STATUS(false);
        }
        resetFilterAndShowList() {
            this.resetFacilitiesList();
            this.resetFloorsList();
            landPlotStore.ZONE_RESET_LAND_PLOT_FILTERS();
            this.$emit('close');
            this.$emit('openFilter');
            landPlotStore.SET_FILTER_STATUS(false);
        }

        resetFloorsList() {
            this.floorsStatus = this.floorsStatus.map(item => {
                item.status = false;
                return item;
            });
        }

        resetFacilitiesList() {
            this.facilitiesStatus = this.facilitiesStatus.map(item => {
                item.status = false;
                return item;
            });
        }

        createFloorsList() {
            const floorsStatus = this.floors.map(item => {
                return {
                    id: item,
                    status: false,
                };
            });

            floorsStatus.forEach((el, idx) => {
                if (
                    Object.values(
                        landPlotStore.ZONE_LAND_PLOT_FILTERS.allowedFloors,
                    ).includes(el.id)
                ) {
                    floorsStatus[idx].status = true;
                }
            });
            //@ts-ignore
            this.floorsStatus = floorsStatus;
        }

        createFacilitiesList() {
            const facilitiesStatus = this.facilities.map(item => {
                return {
                    ...item,
                    status: false,
                };
            });

            facilitiesStatus.forEach((el, idx) => {
                if (
                    Object.values(
                        landPlotStore.ZONE_LAND_PLOT_FILTERS.facilities,
                    ).includes(el.id)
                ) {
                    facilitiesStatus[idx].status = true;
                }
            });
            //@ts-ignore
            this.facilitiesStatus = facilitiesStatus;
        }

        onfilterFloorsChanged(data: { id: number; index: number }) {
            if (
                this.floorsStatus[data.index].status !== true &&
                landPlotStore.ZONE_LAND_PLOT_FILTERS.allowedFloors.includes(
                    data.id,
                )
            ) {
                const index =
                    landPlotStore.ZONE_LAND_PLOT_FILTERS.allowedFloors.findIndex(
                        item => item === data.id,
                    );
                const res = landPlotStore.ZONE_LAND_PLOT_FILTERS.allowedFloors;
                res.splice(index, 1);

                landPlotStore.SET_ZONE_LAND_PLOT_FILTERS({
                    allowedFloors: [...res],
                });
            }
            if (
                this.floorsStatus[data.index].status === true &&
                !landPlotStore.ZONE_LAND_PLOT_FILTERS.allowedFloors.includes(
                    data.id,
                )
            ) {
                const res = landPlotStore.ZONE_LAND_PLOT_FILTERS.allowedFloors;
                landPlotStore.SET_ZONE_LAND_PLOT_FILTERS({
                    allowedFloors: [...res, data.id],
                });
            }
        }

        onfilterFacilityChanged(data: { id: number; index: number }) {
            if (
                this.facilitiesStatus[data.index].status !== true &&
                Object.values(
                    landPlotStore.ZONE_LAND_PLOT_FILTERS.facilities,
                ).includes(data.id)
            ) {
                const res = landPlotStore.ZONE_LAND_PLOT_FILTERS.facilities;
                delete res[data.id];
                landPlotStore.SET_ZONE_LAND_PLOT_FILTERS({ facilities: res });
            }
            if (
                this.facilitiesStatus[data.index].status === true &&
                !Object.values(
                    landPlotStore.ZONE_LAND_PLOT_FILTERS.facilities,
                ).includes(data.id)
            ) {
                const res = landPlotStore.ZONE_LAND_PLOT_FILTERS.facilities;
                res[data.id] = data.id;
                landPlotStore.SET_ZONE_LAND_PLOT_FILTERS({
                    facilities: { ...res },
                });
            }
        }

        @Watch('facilities')
        on() {
            this.createFacilitiesList();
        }

        @Watch('allowedFloors')
        on2() {
            this.createFloorsList();
        }

        @Watch('zone')
        on3() {
            if (!this.zone) {
                this.resetFilter();
            }
        }

        created() {
            this.createFloorsList();
            this.createFacilitiesList();
        }
        mounted() {
            this.createFloorsList();
            this.createFacilitiesList();
        }
    }
