var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"map-header",class:{ 'map-header--hide-layout': _vm.isShowInfoForDetailSection }},[(!_vm.isShowInfoForDetailSection)?_c('div',{staticClass:"map-header__tabs-wrapper"},[_c('div',{staticClass:"map-header__tabs"},_vm._l((_vm.tabs),function(item,i){return _c('button',{key:item.id,staticClass:"map-header__tabs-btn text-nr ff-raleway",class:{ 'map-header__tabs-btn--active': item.active },on:{"click":function($event){return _vm.setActive(i)}}},[_vm._v(" "+_vm._s(item.option)+" ")])}),0),_c('div',{staticClass:"map-header__tabs-mob ff-raleway",class:{
                'map-header__tabs-mob--open-list': _vm.tabsIsOpen,
            }},[_c('div',{staticClass:"map-header__tabs-mob-selected text-sm text-black",on:{"click":function($event){_vm.tabsIsOpen = !_vm.tabsIsOpen}}},[_vm._v(" "+_vm._s(_vm.currentTab.option)+" ")]),_c('div',{staticClass:"map-header__tabs-mob-list",class:{ 'map-header__tabs-mob-list--hide': !_vm.tabsIsOpen }},_vm._l((_vm.tabs),function(item,i){return _c('button',{key:item.id,staticClass:"map-header__tabs-mob-option text-sm text-secondary",class:{
                        'map-header__tabs-mob-option--active': item.active,
                    },on:{"click":function($event){return _vm.setActive(i)}}},[_vm._v(" "+_vm._s(item.option)+" ")])}),0)]),_c('button',{staticClass:"map-header__button ff-raleway",on:{"click":function($event){return _vm.onUploadSchemeImage()}}},[_vm._v(" Скачать 2D схему ")])]):_c('div',{staticClass:"map-header__back"},[_c('CustomButtonBack',{staticClass:"map-header__back-btn",on:{"click":function($event){return _vm.backToFullMap()}}},[_vm._v("Назад")])],1),(
            _vm.currentTab.value === 'master-plan' && _vm.isShowInfoForDetailSection
        )?_c('div',{staticClass:"map-header__filter"},[_c('CustomFilterButton',{staticClass:"map-header__filter-btn",attrs:{"filterIsOpen":_vm.changeStatusFilter},on:{"click":function($event){return _vm.openFilter()}}},[_vm._v(" Фильтр ")]),_c('CustomListButton',{staticClass:"map-header__filter-list",on:{"click":function($event){return _vm.openList()}}},[_vm._v("Список")])],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }