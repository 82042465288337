
    import { landPlotStore } from '@/store/modules/LandPLot';
    import { Component, Vue } from 'vue-property-decorator';
    import CardTemplate from '@/components/layouts/CardTempate.vue';
    import { cardSettingsStore } from '@/store/modules/CardSettings';
    @Component({
        components: { CardTemplate },
    })
    export default class CardRelaxationZone extends Vue {
        widthCard: any = 0;

        get statusCardRelaxationZoneShow() {
            return cardSettingsStore.STATUS_CARD_RELAXATION_ZONE_SHOW;
        }

        get objectStyle() {
            let left = this.position.width / 3 + this.position.left;
            return {
                left: `${left}px`,
                bottom: `calc(100% - ${
                    this.position.bottom - this.position.topMap
                }px + ${this.position.height / 2}px)`,
                opacity: this.statusCardRelaxationZoneShow ? '1' : '0',
            };
        }

        get position() {
            return cardSettingsStore.positionCardRelaxationZone;
        }

        mounted() {
            setTimeout(() => {
                if (
                    this.$refs.houseCard &&
                    this.$refs.houseCard instanceof Element
                )
                    this.widthCard = this.$refs.houseCard.clientWidth;
            });
        }
    }
