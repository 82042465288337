
    import { landPlotStore } from '@/store/modules/LandPLot';
    import { Component, Vue } from 'vue-property-decorator';
    import CardTemplate from '@/components/layouts/CardTempate.vue';
    import { cardSettingsStore } from '@/store/modules/CardSettings';
    @Component({
        components: { CardTemplate },
    })
    export default class CardPlotZone extends Vue {
        widthCard: any = 0;

        get urlBackgroundImage() {
            return this.card.withHouse
                ? require('@/assets/img/Map/houses.jpg')
                : require('@/assets/img/Map/plots.jpg');
        }

        get statusCardPlotShow() {
            return cardSettingsStore.STATUS_CARD_PLOT_ZONE_SHOW;
        }

        get objectStyle() {
            let left = this.position.width / 2 + this.position.left;
            return {
                left: `${left}px`,

                bottom: `calc(100% - ${
                    this.position.bottom - this.position.topMap
                }px + ${this.position.height / 2}px)`,

                opacity: this.statusCardPlotShow ? '1' : '0',
            };
        }

        get card() {
            return landPlotStore.LAND_PLOT_CARD_ZONE;
        }

        get position() {
            return cardSettingsStore.POSITION_CARD_PLOT_ZONE;
        }

        mounted() {
            setTimeout(() => {
                if (
                    this.$refs.houseCard &&
                    this.$refs.houseCard instanceof Element
                )
                    this.widthCard = this.$refs.houseCard.clientWidth;
            });
        }
    }
