
    import { Component, Vue, Prop } from 'vue-property-decorator';
    import CustomButton from '@/components/commons/CustomControls/CustomButton.vue';
    import CustomList from '@/components/commons/CustomList.vue';
    import { CustomListItem } from '@/types/common/CustomList';

    const SliderGallery = () => import('@/components/slider/SliderGallery.vue');
    const SliderGalleryMobile = () =>
        import('@/components/slider/SliderGalleryMobile.vue');

    import { ILandPlot } from '@/types/LandPLot';

    @Component({
        components: {
            CustomButton,
            CustomList,
            SliderGallery,
            SliderGalleryMobile,
        },
    })
    export default class LandPlotModalBottom extends Vue {
        @Prop({
            default: () => ({
                id: 1,
                house: {
                    id: -1,
                    name: '',
                    descriptionIn: '',
                    descriptionOut: '',
                    area: 0,
                    floors: 0,
                    images: [],
                    in: [
                        {
                            id: -1,
                            name: '',
                            value: '',
                        },
                    ],
                    out: [
                        {
                            id: 1,
                            name: '',
                            value: '',
                        },
                    ],
                },
                plot: {
                    id: -1,
                    area: 0,
                    status: 'free',
                    number: 0,
                    cost: 0,
                    description: '',
                    facilitiesIdList: {},
                    images: [],
                    parameters: [],
                },
            }),
        })
        landPlot!: ILandPlot;

        get listBottom(): Array<CustomListItem> {
            if (this.landPlot.house)
                return this.landPlot.house.in.map(i => ({
                    id: i.id,
                    title: i.name,
                    text: i.value,
                }));
            return [];
        }

        openInFullScreen(data: number) {
            this.$emit('openInFullScreenHouseModalGallery', data);
        }
    }
