
    import { Component, Vue } from 'vue-property-decorator';
    @Component({
        components: {},
    })
    export default class MapYandex extends Vue {
        disableScroll = true;

        get isDisableScroll() {
            return this.disableScroll;
        }

        enableScrollMap() {
            this.disableScroll = false;
        }

        disableScrollMap() {
            this.disableScroll = true;
        }
    }
