
    import { Component, Vue, Prop } from 'vue-property-decorator';
    import CloseIcon from '@/components/commons/icons/CloseIcon.vue';
    import CustomFilterButton from '@/components/commons/CustomControls/CustomFilterButton.vue';
    import CustomListButton from '@/components/commons/CustomControls/CustomListButton.vue';
    import CustomButtonBack from '@/components/commons/CustomControls/CustomButtonBack.vue';

    @Component({
        components: {
            CloseIcon,
            CustomFilterButton,
            CustomListButton,
            CustomButtonBack,
        },
    })
    export default class ModalTemplate extends Vue {
        @Prop({ default: false }) darkMode!: boolean;
        @Prop({ default: false }) closeBtn!: boolean;
        @Prop({ default: false }) filterBtn!: boolean;
        @Prop({ default: false }) listBtn!: boolean;
        @Prop({ default: false }) mapInset!: boolean;
        @Prop({ default: false }) scrollBodyLock!: boolean;
        @Prop({ default: false }) scrollBodyLockMobOnly!: boolean;
        @Prop({ default: false }) backToListBtn!: boolean;
        @Prop({ default: false }) templateActionsIs!: boolean;

        switchToList() {
            this.$emit('close');
            this.$emit('openFilter');
        }

        switchToFilter() {
            this.$emit('close');
            this.$emit('openList');
        }

        mounted() {
            if (this.scrollBodyLock) document.body.style.overflow = 'hidden';
            if (
                this.scrollBodyLockMobOnly &&
                window.matchMedia('(max-width: 948px)').matches
            ) {
                document.body.style.overflow = 'hidden';
            }
        }

        beforeDestroy() {
            document.body.style.overflow = 'auto';
        }
    }
